@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
}

button,
input {
  font-family: 'Montserrat', sans-serif;
}

.container {
  max-width: 1140px;
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0;
}
#container-da-imagem {
  width: 300px;
  position: relative;
}
#container-da-imagem img {
  width: 100%
}
img {
  width: 100%
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}