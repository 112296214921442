@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
}

button,
input {
  font-family: 'Montserrat', sans-serif;
}

.container {
  max-width: 1140px;
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0;
}
#container-da-imagem {
  width: 300px;
  position: relative;
}
#container-da-imagem img {
  width: 100%
}
img {
  width: 100%
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.chip {
  font-size: 0.7rem;
  background: linear-gradient(to right, #6190e8, #a7bfe8);
  color: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
}

.emptyList-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}

.blog-wrap {
  max-width: 700px;
  margin: 0 auto;
}

.blog-goBack {
  text-decoration: none;
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 2rem;
  display: block;
}
.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
}

.blog-wrap img {
  width: 100%;
}

.blog-subCategory {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.blog-subCategory > div {
  margin: 1rem;
}
.blog-desc {
  padding: 1rem;
  margin-top: 1.5rem;
}

.blogItem-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}
.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.blogItem-desc {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  color: #a9a9a9;
}

.blogItem-desc::before {
  position: absolute;
  content: '...';
  bottom: 0;
  right: 0;
}

.blogItem-wrap footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: inherit;
}

.blogItem-author {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}

.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
}

@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.home-header {
  text-align: center;
}

.home-header h2 {
  color: #0080ff;
  font-size: 2rem;
}

.home-header h1 {
  font-size: 3rem;
  color: #0f52ba;
  margin-bottom: 1rem;
}

.home-header h1 span {
  color: #b0c4de;
}

.home-header p {
  color: #a9a9a9;
  font-weight: 500;
}

.searchBar-wrap {
  background-color: #f0f0f0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 2.5rem auto 4rem auto;
  padding: 0.5rem;
  border-radius: 5px;
}

.searchBar-wrap form {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.searchBar-wrap input {
  background-color: #f0f0f0;
  outline: none;
  border: none;
}

.searchBar-wrap span {
  padding-right: 0.5rem;
  cursor: pointer;
}

.searchBar-wrap button {
  outline: none;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  background-color: #0f52ba;
  color: #fff;
}

